<template>
    <div>
        <div class="label">{{field.title}}</div>
        <div v-if="warning" class="warning">{{warning.text}}</div>
        <input
            :type="field.inputType || 'text'"
            :value="value"
            v-on:input="handleInput($event.target.value)"
            autocomplete="off">
    </div>
</template>

<script>

export default {
    props: [ 'field', 'warning' ],
    computed: {
        config() {
            return this.$store.state.configs[this.$store.state.activeConfig]
        }
    },
    methods: {
        handleInput(value) {
            this.value = value
            this.$emit('change', value)
        }
    },
    data() {
        return {
            value: this.field.defaultValue || ''
        }
    }
}

</script>

<style src="./Input.scss" lang="scss" scoped></style>